import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React from "react";

interface FeatureProps {
  title: string;
  description: string;
  resourceUrl: string;
  Icon: string | (OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; });
  color: string;
}

export const Feature: React.FC<FeatureProps> = ({
  title,
  description,
  resourceUrl,
  Icon,
  color
}) => (
  <a
    id={resourceUrl}
    href={`#${resourceUrl}`}
    className="feature"
  >
    <h3 className="feature__headline">
      <Icon className="extra-large" fontSize="large" color={color as "inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning"} />
      {title}
    </h3>
    <p className="feature__description">{description}</p>
  </a>
);
