import React from "react";
import OverTheAirDefenders from '../assets/over-the-air-defenders.png'

export const HeroBanner: React.FC = () => {
  return (
    <div className="hero-banner hero-banner--dark-aluminium-aqua">
      <img className="hero-banner__image" src={OverTheAirDefenders} alt="OTA Logo" />
      <h1 className="hero-banner__headline">Welcome to the OTA Updater!</h1>
      <p className="hero-banner__description">
        Experience seamless OTA updates for Arduino hardware with built-in digital signature verification
        and blockchain-backed security for enhanced trust and reliability.
      </p>
      <a
        id="code-sample-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://youtube.com"
        className="button button--secondary"
      >
        Check out this video with a demo
      </a>
    </div>
  );
};
