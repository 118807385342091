import React, { useEffect, useState } from "react";
import { DropzoneComponent } from 'react-dropzone-component'
import "dropzone/dist/min/dropzone.min.css";
import 'react-dropzone-component/styles/filepicker.css'
import { PageLayout } from "../../components/page-layout";
import { deleteOTAUpdate, getOtaUpdates, uploadFirmware } from "../../services/ota-update.service";
import { useAuth0 } from "@auth0/auth0-react";
import './styles.css'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid2, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";
import InputNumber from "rc-input-number";
import { useAppContext } from "src/context/app-context";
import { VariantType } from "notistack";
import { useConfirm } from "material-ui-confirm";
import { OtaUpdateResponse } from "src/models/ota-updates-response";

interface DropzoneType {
  removeFile: () => void;
  removeAllFiles: () => void;
  addFile: (arg0: any) => void;
  processQueue: () => void;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const AdminPage: React.FC = () => {

  const confirm = useConfirm()
  const { handleMessage }: {
    handleMessage: (message: string, variant: VariantType) => void
  } = useAppContext()

  const [accessToken, setAccessToken] = useState<string>("");
  const [otaUpdates, setOtaUpdates] = useState<OtaUpdateResponse[]>([])
  const { getAccessTokenSilently } = useAuth0()
  const [firmwareFile, setFirmwareFile] = useState(null)
  const [value, setValue] = useState(0)
  const [dropzone, setDropzone] = useState<DropzoneType>({} as DropzoneType)
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };


  const config = {
    iconFiletypes: ['.bin'],
    showFiletypeIcon: true,
    postUrl: 'no-url'
  };

  const djsConfig = {
    maxFiles: 1,
    uploadMultiple: false,
    addRemoveLinks: true,
    acceptedFiles: "application/octet-stream",
    autoProcessQueue: false
  };

  function initCallback(dropzone: any) {
    setDropzone(dropzone);
  }

  const eventHandlers = {
    init: (e: any) => initCallback(e),
    addedfile: (file: any) => setFirmwareFile(file),
    maxfilesexceeded: (file: any) => {
      dropzone.removeAllFiles();
      dropzone.addFile(file);
    },
    removedfile: (file: any) => setFirmwareFile(null)
  }

  useEffect(() => {
    (async () => {
      const { data } = await getOtaUpdates()
      setOtaUpdates(data)
    })()
  }, [])


  useEffect(() => {
    let token: string
    (async () => {
      token = await getAccessTokenSilently()
      setAccessToken(token);
    })()
  }, [getAccessTokenSilently]);

  const onSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!value) handleMessage('You must provide a version', 'warning');
    if (!firmwareFile) handleMessage('You must choose a file', 'warning')
      if (otaUpdates.length > 0 && value <= Number(otaUpdates[0].version)) {
        handleMessage('You must provide a version number that is higher than the latest one', 'warning');
      }
    if (!value || !firmwareFile || (otaUpdates.length > 0 && value <= Number(otaUpdates[0].version ?? 0))) return
    const formData = new FormData();
    formData.append('version', value as unknown as string)
    formData.append('file', firmwareFile as unknown as Blob);
    try {
      const { data } = await uploadFirmware(accessToken, formData);
      handleMessage('You managed to upload successfully the firmware', 'success')
      setOtaUpdates(otas => [data, ...otas])
      setFirmwareFile(null)
      setValue(0)
    } catch (error) {
      // @ts-ignore
      handleMessage(error.message, 'error')
    }
  };

  const deleteHandler = async (ota: any) => {
    confirm({ description: `This will permanently delete OTA update version # ${ota.version}` })
      .then(async () => {
        try {
          const { data } = await deleteOTAUpdate(ota.id)
          setOtaUpdates(otas => otas.filter(o => o.id !== data))
          handleMessage('Firmware successfully deleted', 'success')
        } catch (error: any) {
          handleMessage(error.message, 'error')
        }
      }).catch(() => { });
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Admin Panel
        </h1>
        <div className="content__body">

          <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                <Typography>Manage Firmwares</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List sx={{ maxWidth: 600, mx: 'auto', mb: 2, bgcolor: 'background.paper' }}>
                  {otaUpdates.map(o => (
                    <ListItem
                      key={o.id}
                      className="list-item"
                      disableGutters
                      secondaryAction={
                        <IconButton aria-label="comment" onClick={() => deleteHandler(o)}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <LightTooltip title={`Digital Signature: ${o.signature}`} placement="top-start">
                        <ListItemText sx={{ color: 'black', ml: 1 }} primary={`Version # ${o.version}`} />
                      </LightTooltip>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon />}>
                <Typography>Upload new firmware</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form>
                  <Grid2 gap={4} container>
                    <Grid2 size={{ xs: 12, md: 6 }} minWidth={300} width='100%'>
                      <label>Firmware</label>
                      {/*@ts-ignore */}
                      <DropzoneComponent
                        config={config}
                        eventHandlers={eventHandlers}
                        djsConfig={djsConfig} />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 5.5 }} minWidth={300} width='100%' display='flex' flexDirection='column' justifyContent='space-between'>
                      <Box>
                        <label htmlFor="version">Firmware version</label>
                        <InputNumber
                          id="version"
                          min={Number(otaUpdates[0]?.version) + .1}
                          step={0.1}
                          value={value}
                          style={{ width: "100%", fontSize: 16 }}
                          onChange={(e) => setValue(e as number)}
                          required
                        />
                      </Box>
                      <Button
                        sx={{ width: "100%", mt: 6 }}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={onSubmit}>
                        Submit
                      </Button>
                    </Grid2>
                  </Grid2>
                </form>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
