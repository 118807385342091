import { Button } from '@mui/material'
import { VariantType, useSnackbar } from 'notistack'
import React, { useCallback } from 'react'


interface AppContextProps {
    handleMessage(message: string, type: VariantType): void
}

export const AppContext: React.Context<any> = React.createContext<AppContextProps | undefined>({
    handleMessage: () => undefined
})
export const useAppContext = () => {
    const k = React.useContext(AppContext)
    return k
}

export const UserManagerWrapper = (props: any) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleMessage = useCallback(
        (message: string, type: VariantType) => {
            enqueueSnackbar(message, {
                anchorOrigin: { vertical: "top", horizontal: "center" },
                variant: type,
                action: (key) => (
                    <Button style={{ color: "white" }} size="small" onClick={() => closeSnackbar(key)}>
                        &#10006;
                    </Button>
                ),
            });
        },
        [enqueueSnackbar, closeSnackbar]
    );

    return (
        <AppContext.Provider value={{
            handleMessage,
        }}>
            {props.children}
        </AppContext.Provider>
    )
}
