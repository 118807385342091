import React from "react";
import { NavLink } from "react-router-dom";
import OverTheAirDefenders from '../../../assets/logo.png'

export const NavBarBrand: React.FC = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/" style={{ display: 'flex' }}>
        <img
          className="nav-bar__logo"
          src={OverTheAirDefenders}
          alt="Logo"
          width="auto"
          height="40"
        /> <p style={{
          marginTop: 'auto', marginBottom: 'auto'
        }}> <span style={{ fontSize: 18, fontWeight: 100, fontFamily: 'Roboto' }}>Over The Air</span> <br /><span style={{ fontSize: 20, fontWeight: 900, fontFamily: 'Roboto' }}> Defenders</span> </p>
      </NavLink>
    </div>
  );
};
