import React from "react";
import loadingImg from '../assets/loader.svg'

export const PageLoader: React.FC = () => {

  return (
    <div className="loader">
      <img src={loadingImg} alt="Loading..." />
    </div>
  );
};
