import React from "react";
import { LinkResource } from "../models/link-resource";
import { PageFooterHyperlink } from "./page-footer-hyperlink";
import OverTheAirDefenders from '../assets/logo.png'
import { useAuth0 } from "@auth0/auth0-react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GitHubIcon from '@mui/icons-material/GitHub';
import GavelIcon from '@mui/icons-material/Gavel';
import ShieldIcon from '@mui/icons-material/Shield';

export const PageFooter = () => {
  const { loginWithRedirect } = useAuth0();
  const resourceList: LinkResource[] = [
    {
      path: "/privacy-policy",
      label: "Privacy Policy",
      icon: ShieldIcon
    },
    {
      path: "/terms-and-conditions",
      label: "Terms & Conditions",
      icon: GavelIcon
    },
    {
      path: "https://github.com/lucasbbs/ota-updater-frontend",
      label: "Github Repo",
      icon: GitHubIcon
    },
    {
      path: "https://wa.me/12267247739?text=Hello,+Lucas,",
      label: "Contact",
      icon: WhatsAppIcon
    },
  ];

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/profile",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info">
          <div className="page-footer-info__message">
            <p className="page-footer-message__headline">
              <span>This OTA updater is powered by&nbsp;</span>
              <PageFooterHyperlink path={null}>
                <>OTA Defenders</>
              </PageFooterHyperlink>
            </p>
            <p className="page-footer-message__description">
              <PageFooterHyperlink path="https://auth0.com/docs/quickstarts/">
                <>
                  <span>
                    Ensuring seamless and secure firmware updates for any automotive system
                  </span>
                </>
              </PageFooterHyperlink>
            </p>
          </div>
          <div className="page-footer-info__button">
            <button className="button button--secondary" onClick={handleSignUp}>
              Register
            </button>
          </div>
          <div className="page-footer-info__resource-list">
            {resourceList.map((resource) => (
              <div
                key={resource.path}
                className="page-footer-info__resource-list-item"
              >
                <resource.icon className="icon-link-footer" />
                <PageFooterHyperlink path={resource.path}>
                  <>{resource.label}</>
                </PageFooterHyperlink>
              </div>
            ))}
          </div>
        </div>
        <div className="page-footer-grid__brand">
          <div className="page-footer-brand">
            <img
              className="page-footer-brand__logo"
              src={OverTheAirDefenders}
              alt="ota defenders"
              width="40"
              height="auto"
            />
            <PageFooterHyperlink path={null}>
              <span style={{ marginTop: 5 }}>All rights reserved OTA Defenders © {(new Date()).getFullYear()}</span>
            </PageFooterHyperlink>
          </div>
        </div>
      </div>
    </footer>
  );
};
