import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { ProviderWithNavigate } from "./provider-with-navigate";
import "./styles/styles.css";
import { SnackbarProvider } from "notistack";

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(
  <SnackbarProvider maxSnack={3}>
    <BrowserRouter>
      <ProviderWithNavigate>
        <App />
      </ProviderWithNavigate>
    </BrowserRouter>
  </SnackbarProvider>
);
