import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api-response";
import { callApi } from "./api.service";
import { OtaUpdatesResponse } from "src/models/ota-updates-response";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getPublicResource = async (): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/messages/public`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = (await callApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getProtectedResource = async (token: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/ota-update/test`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };

  const { data, error } = (await callApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getAdminResource = async (token: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/ota-update/external`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  };

  const { data, error } = (await callApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const uploadFirmware = async (token: string, formData: FormData): Promise<any> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/ota-update`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${token}`
    },
    data: formData
  };

  const { data, error } = (await callApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getOtaUpdates = async (): Promise<OtaUpdatesResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/ota-update`,
    method: "GET",
    headers: {
      "content-type": "application/json"
    },
  };

  const { data, error } = (await callApi({ config })) as OtaUpdatesResponse;

  return {
    data,
    error,
  };
};

export const deleteOTAUpdate = async (id: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/ota-update/${id}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json"
    },
  };

  return (await callApi({ config }))
}