import React from "react";
import { Feature } from "./Feature";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LinkIcon from "@mui/icons-material/Link";

export const Auth0Features: React.FC = () => {
  const featuresList = [
    {
      title: "Secure Authentication",
      description: "Manage user logins securely with Auth0 Identity Provider, ensuring smooth and safe access to the application. Multi-Factor Authentication (MFA) is deployed for an added layer of security.",
      Icon: LockIcon,
      resourceUrl: "secure-authentication",
      color: "success"
    },
    {
      title: "OTA Firmware Updates",
      description: "Easily deploy Over-the-Air (OTA) firmware updates to your Arduino devices, streamlining the update process and ensuring your devices are always running the latest version.",
      Icon: SettingsIcon,
      resourceUrl: "ota-firmware-updates",
      color: "warning"
    },
    {
      title: "Digital Signature Verification",
      description: "Verify the authenticity and integrity of firmware files through digital signature checks, ensuring that updates are genuine and have not been tampered with.",
      Icon: FingerprintIcon,
      resourceUrl: "digital-signature-verification",
      color: "info"
    },
    {
      title: "Blockchain Security",
      description: "Leverage blockchain technology to securely store and validate firmware updates, ensuring that all data remains immutable, traceable, and tamper-proof throughout the process.",
      Icon: LinkIcon,
      resourceUrl: "blockchain-security",
      color: "error"
    }
  ];

  return (
    <div className="features">
      <h2 className="features__title">Explore Features</h2>
      <div className="features__grid">
        {featuresList.map((feature) => (
          <Feature
            key={feature.title}
            title={feature.title}
            description={feature.description}
            resourceUrl={feature.resourceUrl}
            Icon={feature.Icon}
            color={feature.color}
          />
        ))}
      </div>
    </div>
  );
};
